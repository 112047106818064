const { mapState } = require('vuex')
module.exports = {
    computed: mapState({
        count: state => state.count,

        countAlias: 'count',

        countPlusLocalState (state) {
            return state.count + this.localCount
        }
    }),
    // موقع آپلود رو سرور بعضی قیمتا کامنت بشه مثل  مرجوعی
    // موقع آپلود رو سرور بعضی قیمتا کامنت بشه مثل  مرجوعی
    // موقع آپلود رو سرور بعضی قیمتا کامنت بشه مثل  مرجوعی
    // موقع آپلود رو سرور بعضی قیمتا کامنت بشه مثل  مرجوعی
    // موقع آپلود رو سرور بعضی قیمتا کامنت بشه مثل  مرجوعی
   
    // baseUrl: 'http://localhost:8080/',        
    baseUrl: 'https://newsenator.vestatest.ir/',            
     
    // baseUrl: 'http://172.16.16.50',                   
    // serverBaseUrl: 'http://172.16.16.50', 

    // baseUrl: 'http://172.16.16.148',                   
    // serverBaseUrl: 'http://172.16.16.148',                                    
        
    serverBaseUrl: 'https://newsenator.vestatest.ir'                    
       
  }